<!--
 * @Description: 新闻列表
 * @Author: chenpeng
 * @Date: 2022-05-19 15:02:27
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-19 15:08:07
 * @FilePath: \taiyang-web\src\views\newsList\index.vue
-->
<template>
  <div>
    <!-- 新闻列表 -->
      <NewsList ref="newsListRef" />
  </div>
</template>

<script>
import NewsList from './components/newsList.vue'
export default {
  name: "newsList",
  components: { NewsList },
  data() {
    return {
      // 二级菜单数据
      menuList: [],
    }
  },
}
</script>