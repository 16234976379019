<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 17:36:01
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-20 20:31:46
 * @Description: 新闻列表
-->
<template>
  <div class="response news-container">
    <div class="newslist-wrap">
      <div
        class="newslist-item pointer"
        v-for="(item, index) in newsList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="newslist-item-left">
          <img v-lazy="item.url" alt="" />
        </div>
        <div class="newslist-item-right">
          <h3 class="ellipsis-2">
            {{ item.title }}
          </h3>
          <p class="ellipsis-5">
            {{ item.description }}
          </p>
          <span class="bottom-foot">
            {{ $getDate(item.created_at, 'YMD') }}
          </span>
          <div class="jump-wrap">
            <i class="iconfont icon-jiantou_xiangyou_o"></i>
          </div>
        </div>
      </div>
    </div>

    <Page
      :page-size="form.limit"
      :total="total"
      :current-page="form.page"
      @currentChange="currentChange"
    ></Page>
  </div>
</template>

<script>
import { getNewsList } from '@/api/news/index.js'
export default {
  data() {
    return {
      form: {
        limit: 6,
        page: 1,
      },
      total: 0,
      newsList: [],
      // 二级菜单数据
      menuList: [],
      menuType: null,
    }
  },
  methods: {
    async getNewsList(param) {
      let res = await getNewsList({web: 1, ...this.form})

      if (res.code === this.$httpCode) {
        this.newsList = res.data.data
        this.total = res.data.total
        this.form.page = res.data.current_page
      } else {
        this.newsList = []
        this.total = 0
      }
    },
    currentChange(val) {
      this.form.page = val
      this.getNewsList()
    },
    // 重置数据
    reset() {
      this.newsList = []
      this.total = 0
      this.form = {
        limit: 6,
        page: 1,
      }
    },
    // 初始化
    init() {
      this.reset()
      this.getNewsList()
    },
    toDetail(item) {
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      const routeData = this.$router.resolve({
        name: 'newsInfo',
        query: query,
      })
      window.open(routeData.href, '_blank')
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.news-container {
  padding: 62px 0;
  .newslist-wrap {
    .newslist-item {
      height: 254px;
      display: flex;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      .newslist-item-left {
        flex: 0 0 386px;
        width: 386px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .newslist-item-right {
        margin-left: 22px;
        // margin-right: 14px;
        padding: 20px 0;
        position: relative;
        flex: 1;
        h3 {
          height: 48px;
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
        p {
          height: 130px;
          margin: 7px 0;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5b5a5a;
          line-height: 26px;
        }
        .bottom-foot {
          display: inline-block;
          padding-right: 40px;
          height: 26px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5b5a5a;
        }
        .jump-wrap {
          position: absolute;
          bottom: 14px;
          right: 4px;
          i {
            font-size: 30px;
            color: #5b5a5a;
          }
        }
      }
      &:hover {
        background-color: #e3a63b;
        .newslist-item-right {
          h3 {
            color: #fff;
          }
          p {
            color: #fff;
          }
          .bottom-foot {
            color: #fff;
          }
          .jump-wrap {
            i {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>