/*
 * @Author: 蒋威
 * @Date: 2022-04-16 11:18:05
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-10 17:05:51
 * @Description:项目模块
 */
import request from '@/utils/request'
// 项目详情
export function detailsByProjectId(params) {
  return request({
    url: `/api/web/detailsByProjectId`,
    method: 'get',
    params,
  })
}
