<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 11:28:34
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-09 12:45:02
 * @Description: 关于我们 合作伙伴
-->
<template>
  <div class="response partner-container">
    <div class="partner-wrap">
      <div
        class="partner-item"
        v-for="(item, index) in partnerList"
        :key="index"
      >
        <div class="img-box">
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="partner-name">
          <span class="ellipsis-2">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerList: [
        {
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          name: '腾讯·大湘网',
        },
        {
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          name: '腾讯·大湘网',
        },
        {
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          name: '腾讯·大湘网',
        },
        {
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          name: '腾讯·大湘网',
        },
        {
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          name: '腾讯·大湘网',
        },
        {
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          name: '腾讯·大湘网',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.partner-container {
  padding: 70px 0;
  .partner-wrap {
    display: flex;
    flex-wrap: wrap;
    .partner-item {
      flex: 0 0 285px;
      width: 285px;
      margin-bottom: 20px;
      margin-right: 20px;
      height: 170px;
      border: 1px solid #cecfce;
      .img-box {
        width: 100%;
        height: 119px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .partner-name {
        height: 48px;
        padding: 4px 6px;
        border-top: 1px solid #cecfce;
        background: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 16px;
          white-space: normal;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #242121;
          line-height: 20px;
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>