<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 09:46:52
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-05 08:34:06
 * @Description: 项目详情
-->
<template>
  <div class="response details-container">
    <!-- 项目介绍 -->
    <div class="project-info">
      <div class="project-info-left">
        <img v-lazy="details.url" alt="" />
      </div>
      <div class="project-info-right">
        <h3 class="project-title ellipsis">{{ details.name }}</h3>
        <p class="ellipsis-6">
          {{ details.target }}
        </p>
      </div>
    </div>

    <!-- tab 切换 -->
    <div class="tabMenu">
      <div
        class="tabMenu-item pointer"
        v-for="(item, index) in tabMenuList"
        :key="index"
        :class="activityId == item.id ? 'active' : ''"
        @click="changeTabMenu(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <!-- 中间内容 -->
    <div class="activity-container">
      <div
        class="activity-left"
        :class="
          newsList.length < 1 && activityWebList.length < 1 ? 'norightBox' : ''
        "
      >
        <!-- 富文本 -->
        <template v-if="activityType == 'richText'">
          <div v-html="$getHtml(details.content)" class="article-container"></div>
        </template>
        <!-- 进度 -->
        <template v-if="activityType == 'speed' && drogress.length > 0">
          <div class="speed-wrap">
            <div
              class="speed-item"
              v-for="(item, index) in drogress"
              :key="index"
            >
              <div class="speed-item-date">
                {{ $getDate(item.created_at, 'YMD') }}
              </div>
              <div class="speed-item-info pointer">
                <div class="speed-item-title ellipsis-2">
                  {{ item.name }}
                </div>
                <div class="speed-item-text" v-html="$getHtml(item.content)"></div>
                <div class="speed-item-imgbox">
                  <template
                    v-for="(imgItems, imgIndex) in item.subsidiary_files"
                  >
                    <img
                      v-if="imgItems.url"
                      :key="imgIndex"
                      v-lazy="imgItems.url"
                      alt=""
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="activity-right"
        v-if="newsList.length > 0 || activityWebList.length > 0"
      >
        <!-- 推荐新闻 -->
        <RecommendNews :list="newsList" v-if="newsList.length > 0" />
        <!-- 推荐活动 -->
        <RecommendActivity
          :list="activityWebList"
          v-if="activityWebList.length > 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RecommendNews from '@/components/RecommendNews.vue'
import RecommendActivity from '@/components/RecommendActivity.vue'

export default {
  components: { RecommendNews, RecommendActivity },
  props: {
    details: {
      type: Object,
      default: {},
    },
    drogress: {
      type: Array,
      default: () => {
        return []
      },
    },
    newsList: {
      type: Array,
      default: () => {
        return []
      },
    },
    activityWebList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      activityType: 'richText',
      activityId: 0,
      tabMenuList: [
        {
          name: '项目介绍',
          id: 0,
          type: 'richText',
        },
        {
          name: '项目进度',
          id: 1,
          type: 'speed',
        },
      ],
    }
  },
  methods: {
    changeTabMenu(item) {
      this.activityId = item.id
      this.activityType = item.type
    },
  },
}
</script>

<style lang="scss" scoped>
.details-container {
  .project-info {
    margin: 77px 0 68px 0;
    height: 280px;
    display: flex;
    .project-info-left {
      width: 612px;
      flex: 0 0 612px;
      height: 280px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .project-info-right {
      width: 588px;
      flex: 0 0 588px;
      padding: 50px 40px 50px 40px;
      background-color: #e3a63b;
      .project-title {
        position: relative;
        height: 40px;
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 26px;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 87px;
          height: 4px;
          background: #ffffff;
        }
      }
      p {
        margin-top: 12px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }

  .activity-container {
    // min-height: 800px;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    .activity-left {
      width: 860px;
      flex: 0 0 860px;

      &.norightBox {
        width: 100%;
        flex: 0 0 100%;
      }
      .speed-wrap {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 7px;
          bottom: 0;
          width: 1px;
          background: #cecfce;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 14px;
          height: 14px;
          background: #e3a63b;
          border-radius: 50%;
          z-index: 2;
        }
        .speed-item {
          position: relative;
          padding-left: 30px;
          margin-bottom: 30px;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 14px;
            background: #e3a63b;
            border-radius: 50%;
            z-index: 2;
          }
          .speed-item-date {
            height: 20px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #a09c9c;
            line-height: 20px;
          }
          .speed-item-info {
            margin-top: 20px;
            // height: 276px;
            background: #ffffff;
            padding: 20px 20px;
            box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.01),
              0px 6px 15px 1px rgba(0, 0, 0, 0.02),
              0px 29px 67px 1px rgba(0, 0, 0, 0.03);
            .speed-item-title {
              height: 48px;
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #242121;
              line-height: 24px;
            }
            .speed-item-text {
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5b5a5a;
              line-height: 20px;
              word-wrap: break-word;
              ::v-deep p {
                line-height: 32px;
                margin: 20px 0;
              }
              ::v-deep span {
                line-height: 28px;
              }
            }
            .speed-item-imgbox {
              display: flex;
              margin-top: 20px;
              flex-wrap: wrap;
              img {
                flex: 0 0 190px;
                height: 125px;
                width: 190px;
                margin-right: 10px;
                margin-bottom: 10px;
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .activity-right {
      width: 290px;
      flex: 0 0 290px;
    }
  }
}
.article-container{
    word-wrap: break-word;
    ::v-deep p {
      line-height: 32px;
      margin: 20px 0;
    }
    ::v-deep span {
      line-height: 28px;
    }
}
</style>