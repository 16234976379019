<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 13:39:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-16 16:45:16
 * @Description: 公益项目
-->

<template>
  <div class="homepage-wrap">
    <NavBox @changeMenu="changeMenu" :menuList="menuList" ref="NavBoxRef" />
    <Banner :bannerList="bannerList"></Banner>
    <!-- 项目列表 -->

    <template v-if="['list', 'all'].includes(menuType) && !showDetail">
      <ProjectList
        v-show="!load"
        :id="columnId"
        @toDetail="toDetail"
        ref="projectListRef"
      />
    </template>
    <!-- 项目详情 -->
    <template v-if="['', 'info', null].includes(menuType) || showDetail">
      <ProjectDetail
        v-show="!load"
        :details="projectDetails"
        :drogress="projectProgress"
        :newsList="newsList"
        :activityWebList="activityWebList"
      />
    </template>
  </div>
</template>

<script>
import ProjectList from './components/ProjectList.vue'
import ProjectDetail from './components/ProjectDetail.vue'
import { channelById } from '@/api/menu/index.js'
import { queryByChannelId } from '@/api/news/index.js'
import { websiteAds_list } from '@/api/homePage/index.js'
import { detailsByProjectId } from '@/api/project/index.js'
export default {
  name: 'project',
  inject: ['reload'],
  components: { ProjectList, ProjectDetail },
  data() {
    return {
      // 是否在加载数据
      load: true,
      // 单个项目
      newsContent: {},
      showDetail: false,
      menuType: null,
      // 选中的二级菜单
      activeItem: {},
      // 栏目id
      columnId: null,
      // 二级菜单数据
      menuList: [],
      // 轮播图
      bannerList: [],
      // 项目详情
      projectDetails: {},
      // 项目进度
      projectProgress: [],
      // 推荐新闻
      newsList: [],
      // 推荐活动
      activityWebList: [],
    }
  },
  methods: {
    getmenuType() {
      const id = this.$route.query.id
      if (id) {
        this.menuList.map((item) => {
          if (item.id - 0 == id - 0) {
            this.columnId = item.id
            this.activeItem = item
            this.menuType = item.type
          }
        })
      } else {
        this.columnId = this.menuList[0].id
        this.activeItem = this.menuList[0]
        this.menuType = this.menuList[0].type
      }
    },
    changeMenu(item) {
      this.menuType = item.type
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      this.reload()
      this.$router.push({
        name: this.$route.name,
        query: query,
      })
    },
    async toDetail(item) {
      // for (let index = 0; index < this.menuList.length; index++) {
      //   if (item.id != this.menuList[index]) {
      //     const moduleId = this.$route.query.moduleId
      //     let query = {}
      //     if (moduleId) {
      //       query = { id: item.id, moduleId: moduleId }
      //     } else {
      //       query = { id: item.id }
      //     }
      //     this.reload()
      //     this.$router.push({ name: this.$route.name, query: query })
      //   }
      //   break
      // }
      const res = await detailsByProjectId({
        projectId: item.id || this.$route.query.id,
      })
      if (res.code === this.$httpCode) {
        console.log(res.data)
        this.projectDetails = res.data.projectDetails
        this.projectProgress = res.data.projectProgress
        this.newsList = res.data.newsList
        this.activityWebList = res.data.activityWebList
        this.showDetail = true
      } else {
        this.showDetail = false
      }
    },
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 2,
        page: 1,
        limit: 1,
        column_id: this.$route.query.moduleId,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.data
      }
    },

    async queryByChannelId() {
      let res
      this.load = true
      switch (this.menuType) {
        case 'all':
          if (!this.showDetail) {
            this.$nextTick(() => {
              this.$refs.projectListRef && this.$refs.projectListRef.init()
            })
          }
          break
        case 'list':
          if (!this.showDetail) {
            this.$nextTick(() => {
              this.$refs.projectListRef && this.$refs.projectListRef.init()
            })
          }
          break
        case 'info':
        case '':
        case null:
          this.toDetail(this.activeItem)
          break
        default:
          break
      }
      this.load = false
    },
    async init() {
      this.getBannerList()
      const res = await channelById({ diff: 'web', id: this.$route.query.moduleId })
      if (res.code === this.$httpCode) {
        this.menuList = res.data.websiteChannelVoList || []
        if (this.menuList.length < 1) {
          this.columnId = res.data.WebsiteChannel.id
          this.menuType = res.data.WebsiteChannel.type
          this.activeItem = { id: res.data.WebsiteChannel.id }
        } else {
          this.getmenuType()
        }
        this.queryByChannelId()
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
}
</style>
