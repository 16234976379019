<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 13:39:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-05 08:33:40
 * @Description: 新闻动态
-->

<template>
  <div class="homepage-wrap">
    <!-- 二级菜单 -->
    <NavBox @changeMenu="changeMenu" :menuList="menuList" />
    <Banner :bannerList="bannerList"></Banner>
    <!-- 新闻列表 -->
    <template v-if="model != 'partner' && ['list', 'all'].includes(menuType)">
      <NewsList ref="newsListRef" :id="columnId" />
    </template>
    <!-- 单个新闻 -->
    <template
      v-if="model != 'partner' && ['', 'info', null].includes(menuType)"
    >
      <!-- 没有推荐新闻 推荐活动 -->
      <div class="response newsContent">
        <div  class="activity-content" v-html="$getHtml(newsContent.content)"></div>
      </div>
    </template>
    <!-- 合作伙伴 -->
    <template v-if="model == 'partner'">
      <PartnerList :list="partnerList" />
    </template>
  </div>
</template>

<script>
import NewsList from './components/newsList.vue'
import PartnerList from './components/PartnerList.vue'
import { channelById } from '@/api/menu/index.js'
import { queryByChannelId } from '@/api/news/index.js'
import { websiteAds_list } from '@/api/homePage/index.js'
export default {
  name: 'news',
  inject: ['reload'],
  components: { NewsList, PartnerList },
  data() {
    return {
      // 单个新闻
      newsContent: {},
      // 二级菜单数据
      menuList: [],
      menuType: null,
      // 选中的二级菜单
      activeItem: {},
      // 栏目id
      columnId: null,
      model: null,
      // 合作伙伴
      partnerList: [],
      // 轮播图
      bannerList: [],
    }
  },
  methods: {
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-09 10:31:35
     * @Description: 二级菜单选中切换
     */
    changeMenu(item) {
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      this.reload()
      this.$router.push({
        name: this.$route.name,
        query: query,
      })
    },
    getmenuType() {
      const id = this.$route.query.id
      if (id) {
        this.menuList.map((item) => {
          if (item.id - 0 == id - 0) {
            this.columnId = item.id
            this.activeItem = item
            this.menuType = item.type
            this.model = item.model
          }
        })
      } else {
        this.columnId = this.menuList[0].id
        this.activeItem = this.menuList[0]
        this.menuType = this.menuList[0].type
        this.model = this.menuList[0].model
      }
    },
    async queryByChannelId() {
      let res
      if (this.model == 'partner') {
        res = await queryByChannelId({
          id: this.activeItem.id,
          limit: 9999,
          page: 1,
        })

        if (res.code === this.$httpCode) {
          this.partnerList = res.data.cooperatorPage.data || []
        } else {
          this.partnerList = []
        }
      } else {
        switch (this.menuType) {
          case 'all':
            this.$nextTick(() => {
              this.$refs.newsListRef && this.$refs.newsListRef.init()
            })
            break
          case 'list':
            this.$nextTick(() => {
              this.$refs.newsListRef && this.$refs.newsListRef.init()
            })
            break
          case 'info':
          case '':
          case null:
            res = await queryByChannelId({
              id: this.activeItem.id,
            })
            if (res.code === this.$httpCode) {
              this.newsContent = res.data.newsContent
            } else {
              this.newsContent = {}
            }
            break
          default:
            break
        }
      }
    },
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 2,
        page: 1,
        limit: 1,
        column_id: this.$route.query.moduleId,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.data
      }
    },
    async init() {
      this.getBannerList()
      const res = await channelById({
        diff: 'web',
        id: this.$route.query.moduleId,
      })
      if (res.code === this.$httpCode) {
        this.menuList = res.data.websiteChannelVoList || []
        // 如果存在二级菜单
        if (this.menuList.length < 1) {
          this.columnId = res.data.WebsiteChannel.id
          this.menuType = res.data.WebsiteChannel.type
          this.model = res.data.WebsiteChannel.model
          this.activeItem = { id: res.data.WebsiteChannel.id }
        } else {
          this.getmenuType()
        }
        this.queryByChannelId()
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
  .newsContent {
    padding: 40px 0;
    .activity-content{
      word-wrap: break-word;
      ::v-deep p {
        line-height: 32px;
        margin: 20px 0;
      }
      ::v-deep span {
        line-height: 28px;
      }
    }
  }
}
</style>
