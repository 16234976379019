<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 09:42:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-10 17:02:29
 * @Description: 项目列表
-->
<template>
  <div class="response publicWelfare-container">
    <div class="publicWelfare-wrap">
      <template v-for="(item, index) in projectList">
        <div
          class="publicWelfare-item pointer"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="img-wrap">
            <img v-lazy="item.url" alt="" />
          </div>
          <!-- 标题 简介 -->
          <div class="bottom-wrap">
            <p class="ellipsis-2">{{ item.name }}</p>
            <h3 class="ellipsis-2">{{ item.target }}</h3>
          </div>
        </div>
      </template>
    </div>

    <Page
      :page-size="form.limit"
      :total="total"
      :current-page="form.page"
      @currentChange="currentChange"
    ></Page>
  </div>
</template>

<script>
import { queryByChannelId } from '@/api/news/index.js'
export default {
  name: 'ProjectList',
  components: {},
  props: {
    id: {
      type: Array | String,
    },
  },
  data() {
    return {
      form: {
        limit: 6,
        page: 1,
      },
      total: 0,
      projectList: [],
    }
  },
  methods: {
    toDetail(item) {
      this.$emit('toDetail', item)
    },
    async queryByChannelId(param) {
      let res = await queryByChannelId({ id: this.id, ...this.form })

      if (res.code === this.$httpCode) {
        this.projectList = res.data.projectPage.data
        this.total = res.data.projectPage.total
        this.form.page = res.data.projectPage.current_page
      } else {
        this.projectList = []
        this.total = 0
      }
      console.log(res, this.total, 'ssdtgvss')
    },
    currentChange(val) {
      this.form.page = val
      this.queryByChannelId()
    },
    // 重置数据
    reset() {
      this.projectList = []
      this.total = 0
      this.form = {
        limit: 6,
        page: 1,
      }
    },
    // 初始化
    init() {
      this.reset()
      this.queryByChannelId()
    },
  },
}
</script>

<style lang="scss" scoped>
.publicWelfare-container {
  padding: 70px 0;
  .publicWelfare-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .publicWelfare-item {
      width: 387px;
      flex: 0 0 387px;
      height: 447px;
      margin-right: 18px;
      position: relative;
      margin-bottom: 18px;
      // background-color: #e3a63b;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .img-wrap {
        width: 387px;
        height: 308px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .bottom-wrap {
        height: 139px;
        width: 100%;
        padding: 28px 20px 0 20px;
        p {
          height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: 400;
          color: #242121;
          line-height: 20px;
        }
        h3 {
          height: 32px;
          margin-top: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5b5a5a;
          line-height: 16px;
        }
      }
      &:hover {
        background-color: #e3a63b;
        .bottom-wrap {
          p {
            color: #fff;
          }
          h3 {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>