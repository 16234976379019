<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 13:39:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-05 08:32:53
 * @Description: 关于我们
-->

<template>
  <div class="homepage-wrap">
    <NavBox @changeMenu="changeMenu" :menuList="menuList" />
    <Banner url="" :bgImg="require('@/assets/img/banner/Frame 3.png')"></Banner>
    <template v-if="menuType == 'list'">
      <!-- 合作伙伴 -->
      <PartnerList />
    </template>
    <template v-else>
      <!-- 富文本 -->
      <div
        class="response article-container"
        v-html="$getHtml(content)"
      ></div>
    </template>
  </div>
</template>

<script>
import PartnerList from './components/PartnerList.vue'
export default {
  name: 'about',
  inject: ['reload'],
  components: { PartnerList },
  data() {
    return {
      // 二级菜单数据
      menuList: [
        { name: '关于我们', id: 7 },
        { name: '太阳文化', id: 8 },
        { name: '合作伙伴', id: 9, type: 'list' },
        { name: '太阳文化', id: 10 },
        { name: '太阳文化', id: 11 },
      ],
      // menu 类型
      menuType: null,
      content: '富文本内容',
    }
  },
  methods: {
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-09 11:15:54
     * @Description: 获取菜单类型
     */
    getmenuType() {
      const id = this.$route.query.id
      if (id) {
        this.menuList.map((item) => {
          if (item.id - 0 == id - 0) {
            this.menuType = item.type
          }
        })
      } else {
        this.menuList.length > 0
          ? (this.menuType = this.menuList[0].type)
          : (this.menuType = null)
      }
    },
    changeMenu(item) {
      this.menuType = item.type
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      this.reload()
      this.$router.push({
        name: this.$route.name,
        query: query,
      })
    },
  },
  created() {
    this.getmenuType()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
  .article-container {
    padding: 80px 0;
    word-wrap: break-word;
    ::v-deep p {
      line-height: 32px;
      margin: 20px 0;
    }
    ::v-deep span {
      line-height: 28px;
    }
  }
}
</style>
